import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "🤝-the-playdough-protocols",
      "style": {
        "position": "relative"
      }
    }}>{`🤝 The Playdough Protocols`}<a parentName="h1" {...{
        "href": "#%F0%9F%A4%9D-the-playdough-protocols",
        "aria-label": "🤝 the playdough protocols permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Sealing the deal`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod1-img/indusseals.gif",
            "alt": "Seals and Sealing"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://nakamotoinstitute.org/the-playdough-protocols/" mdxType="Button">
    Read It
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <p>{`The above essay is about the history of seals and how, using the digital equivalent of seals,
we can bring data integrity and unforgeable identities to online commerce. Written by Nick Szabo
in 2002, it provides a unique window into understanding why blockchains matter beyond the purely
monetary applications.`}</p>
    <h2 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h2" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We have chosen this essay specifically because it shows how money and trade led to
the development of written language; an idea we began exploring with
`}<a parentName="p" {...{
        "href": "/learn/module-0/money-language"
      }}>{`Andreas Antonopoulos`}</a>{` in the previous module. Moreover, it
speaks directly to:`}</p>
    <Process mdxType="Process">
      <p>{`Why we should (continue to) build censorship resistant tools`}</p>
      <p>{`How we have always, and can continue to, secure our most valuable communications.`}</p>
    </Process>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Data integrity is `}<strong parentName="p">{`critical`}</strong>{` to trade and trust relationships. Without some way to guarantee
the quality and provenance of goods, no meaningful trade can occur. In fact, it was this need which led almost directly to the development of the first written symbols. To understand this history, we need to look into how such symbols, carved into clay and stone seals, were used to guarantee integrity.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Let‘s distinguish between seals, the often cleverly carved cylinders or stamps that make the
impressions, and sealings, the resulting impressions rolled or stamped on wet clay, and the clay
they were impressed upon. Sealings of clay were wrapped around rope knots to seal bales of goods,
and around the rims of wicker baskets and pottery jars to seal in their contents `}{`[...]`}{` Seals were
carved from hard materials – usually stone but sometimes faience, glass, metal, wood, or bone.
Sometimes sun-dried or baked clay itself was used. The Greeks and Romans used signet rings, their
action ends shaped from metal or carved from gems, to stamp wax."`}</p>
    </blockquote>
    <h3 {...{
      "id": "faith-and-finance",
      "style": {
        "position": "relative"
      }
    }}>{`Faith and Finance`}<a parentName="h3" {...{
        "href": "#faith-and-finance",
        "aria-label": "faith and finance permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"The earliest stamp seals found were used in Iran in 5,000 B.C. Later on, archaeologists can
use both the trade in seals themselves, as well as the distances between seals and the corresponding sealings, to trace long-distance trade networks."`}</p>
    </blockquote>
    <p>{`It is a critical and underappreciated point:`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 The means of securing our trade, and therefore trust, relationships are also the means by which we can understand the network within which they operate. `}</p>
    </blockquote>
    <p>{`This is still true today, and provides some neat context for why a shared, global, ownerless, borderless means of recording history is so vital and interesting a development.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"For the Sumerians a business protocol was also a religious ritual, and the reverse was usually
true as well. In the Middle East seal breaking became one of the most important of these rituals,
with terrifying spiritual consequences if the seal were broken by the wrong person or at the wrong time."`}</p>
    </blockquote>
    <p>{`In some important ways obscured in our secular era, finance has always had to do with faith, both in the sense that religious institutions and rituals (like seal-breaking) established far wider trade networks and in the far simpler sense that any financial instrument fundamentally has to do with belief (either in a person or in some future event). Next week, we'll invite Nick back to talk about money as a token of delayed reciprocal altruism, but it is far simpler and more direct to point out that `}<strong parentName="p">{`money is a token of faith`}</strong>{`. What you believe informs what you pay attention to and how you act, which define what you vest value in. As value is generated from trust in clearly shared truths, the more effective your beliefs are at getting others to behave in similar ways, the more value will ultimately accrue to them.`}</p>
    <p>{`Here is the heart of what Kernel is trying to teach you. The stories you tell yourself about who you are and how you have come to find yourself in this unique space and time rest beneath and inform fundamentally everything you will learn about trust and money and speech and freedom and all the other words you're bound to hear bandied about the blockchain.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Q; Redemption, reckoning, reconciliation, contract, bond and so many other words all prove the shared roots of faith and finance. In this sense, we can say that money is a `}{`_`}{`_`}{`_`}{`_`}{`_`}{` of `}{`_`}{`_`}{`_`}{`_`}{`_`}{`.`}</p>
        <p>{`token, faith.`}</p>
      </Card>
    </Flash>
    <p>{`So read on, because we've reached the part where trade and money lead directly to the development of writing:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"The first documents ever written, in the 4th millennium B.C., were about wheat and barley,
and were sealed. Far earlier still, at least as far back as 8,000 B.C., archeologists have found
even more salient artifacts – vast numbers of little clay tokens `}{`[...]`}{` Now we know that these
tokens led directly to what are the very basics of our civilization – reading, writing, and arithmetic."`}</p>
    </blockquote>
    <h3 {...{
      "id": "writings-story",
      "style": {
        "position": "relative"
      }
    }}>{`Writing's Story`}<a parentName="h3" {...{
        "href": "#writings-story",
        "aria-label": "writings story permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Soon after 4,000 B.C., the clay tokens were combined with the idea of sealing to create
bills of lading and warehouse receipts. To create a bill of lading for a consignment of sheep,
the owner put in a one-sheep token for every sheep. Every time he counted five sheep, a five-sheep
token could be substituted for a one-sheep token. Once the owner and the consignee agreed on
the count, the tokens were placed in a wet clay envelope. The owner and the consignee rolled
their seals over the envelope, then let it dry. `}{`[...]`}{` The receipt was tokens sealed in an
envelope – when the owner got hungry, or wanted to sell to the hungry, or wanted the seed to
plant next spring – he would take the envelope to the warehouse. The claimant and the warehouse
operator would inspect the seal, break it, inspect the tokens, and then deliver the goods.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"It would be nice if one could learn the contents of the envelope – the number and kind of
tokens – without having to take the ominous and irreversible step of breaking the seal. Around
3,400 B.C. in Sumer, marks started appearing on the outside of these envelopes. These marks
were simply made by the tokens themselves. The different shapes and sizes of the tokens created
correspondingly unique impressions, and thus the same symbols `}{`[...]`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"The evolution of writing proceeded from there. A hundred years later reed styluses were being
used to mimic the clay token marks. Over the centuries, scribes supplemented or replaced
token-derived symbols with pictographs for the objects. The pictographs attempted to depict the
object visually. Both kinds of symbols became stylized as wedges, or “cuneiform”, optimized for
the reed stylus. Still later, words represented by neither pictographs nor token-derived symbols
come to be represented by a rebus."`}</p>
    </blockquote>
    <p>{`And that's all she wrote! Well, not quite, but it is one of the more believable stories about
the development of the written word and takes us deeper into the heart of Kernel's efforts.`}</p>
    <h3 {...{
      "id": "sealing-the-deal",
      "style": {
        "position": "relative"
      }
    }}>{`Sealing the Deal`}<a parentName="h3" {...{
        "href": "#sealing-the-deal",
        "aria-label": "sealing the deal permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`While historically fascinating, seals were not the only method of providing evidence of foul play:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Along with the tamper evident clay, the Sumerians developed a kind of virtual tamper evidence.
It took the form of two sets of numbers. On the front of the tablet, each group of commodities
would be recorded separately – for example on the front would be recorded 120 pots of wheat, 90
pots of barley, and 55 goats. On the reverse would simply be recorded “265” – the same objects
counted again, probably in a different order, and without bothering to categorize them `}{`[...]`}{`
Checksums still form the basis of modern accounting. Indeed, the principle of double entry
bookkeeping is based on two sets of independently derived numbers that must add up to the same number."`}</p>
    </blockquote>
    <p>{`From here, Szabo describes modern means of providing tamper evidence and describes the use of
cryptography - that is, secret writing - to provide both unspoofable checksums with one-way hash
functions, as well as the development of digital signatures.`}</p>
    <p>{`So, what does this all have to do with Ethereum's state and history? Well, apart from providing
the history of our civilizational evolution towards global platforms capable of ensuring data
integrity at scales never before possible, which thereby allow for trade in ways and at scales
not before possible, it also sets the scene nicely for some of the modules to come. `}</p>
    <p>{`Bitcoin and Ethereum are not some aberration of the digital age: they are the next block in a timeless story about the evolution of trust and its concomitant effect on the meaning of being human. Like the Ancient Sumerians prove, nowhere is the ongoing development more obvious than in our application of the twin practices of faith and finance.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      